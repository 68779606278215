
import { Component } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'
import SettingsCard from '@/components/SettingsCard.vue'
import { objectives } from '@/helpers/data'
@Component({ components: { SettingsCard } })
export default class ConfigureCampaign extends VueStrong {
  required = (val: string): boolean | string => !!val || 'Required'
  isObjective = (val: string) =>
    ['OUTCOME_ENGAGEMENT', 'OUTCOME_TRAFFIC', 'OUTCOME_LEADS', 'OUTCOME_SALES'].includes(val)
  get campaign() {
    return this.$store.state.insight.AdCampaignAdsets.campaign
  }
  get objective() {
    if (!this.campaign.objective.includes('OUTCOME_ENGAGEMENT', 'OUTCOME_TRAFFIC', 'OUTCOME_LEADS', 'OUTCOME_SALES'))
      return null
    return this.campaign.objective
  }
  set objective(value) {
    this.campaign.objective = value
    this.updateCampaign()
  }

  get objectives() {
    return objectives
  }

  get isCampaignActive(): boolean {
    return this.campaign.status === 'ACTIVE'
  }

  set isCampaignActive(newValue: boolean) {
    this.campaign.status = newValue ? 'ACTIVE' : 'PAUSED'
  }

  updateCampaign() {
    this.$store.commit('insight/setCampaing', this.campaign)
  }
  async onContinue() {
    const valid = (this.$refs.formCampaign as any).validate()
    if (!valid) return
    this.$emit('continue')
  }
}
