import { render, staticRenderFns } from "./ConfigureAdSets.vue?vue&type=template&id=60a7b039&scoped=true&"
import script from "./ConfigureAdSets.vue?vue&type=script&lang=ts&"
export * from "./ConfigureAdSets.vue?vue&type=script&lang=ts&"
import style0 from "./ConfigureAdSets.vue?vue&type=style&index=0&id=60a7b039&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60a7b039",
  null
  
)

export default component.exports