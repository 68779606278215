
import { Component, Prop } from 'vue-property-decorator'
import SmoothedLineChart from '@/components/SmoothedLineChart.vue'
import VueStrong from '@/VueStrong'

type IBaseInsightStat = 'reach' | 'clicks' | 'impressions' | 'frequency' | 'spend'
type ICostInsightStat = 'ctr' | 'cpm' | 'cpc' | 'cpp'

@Component({ components: { SmoothedLineChart } })
export default class InsightsDialog extends VueStrong {
  @Prop({ required: true }) campaign!: yakkyo.IFacebookCampaign | any
  date = null
  menu = false

  select = ['Reach', 'Clicks', 'Impressions', 'Frequency']
  items = ['Reach', 'Clicks', 'Impressions', 'Frequency', 'Spend', 'Ctr', 'Cpm', 'Cpc', 'Cpp']

  baseStatTotal(stat: IBaseInsightStat) {
    const total = this.campaign.insights.reduce((acc, insight) => {
      let val = acc + insight[stat]
      if (this.date) {
        const fromDate = new Date(this.date)
        const insightDate = new Date(insight.until)
        if (insightDate < fromDate) val = acc
      }
      return val
    }, 0)

    return stat === 'spend' ? total.toFixed(2) : total
  }

  costStatTotal(stat: ICostInsightStat) {
    const clicks = this.baseStatTotal('clicks')
    const impressions = this.baseStatTotal('impressions')
    const spend = this.baseStatTotal('spend')
    const purchases = this.getPurchases(this.campaign.insights || [])

    if (stat === 'ctr') {
      const ctr = (clicks / impressions) * 100
      return impressions > 0 ? ctr.toFixed(2) : 0
    } else if (stat === 'cpm') {
      const cpm = (spend / impressions) * 1000
      return impressions > 0 ? cpm.toFixed(2) : 0
    } else if (stat === 'cpc') {
      const cpc = spend / clicks
      return clicks > 0 ? cpc.toFixed(2) : 0
    } else if (stat === 'cpp') {
      const cpp = spend / purchases
      return purchases > 0 ? cpp.toFixed(2) : spend
    }
  }

  get baseChartData() {
    return this.campaign.insights
      .reduce(
        (acc, insight) =>
          (acc = [
            ...acc,
            {
              reach: insight.reach,
              clicks: insight.clicks,
              impressions: insight.impressions,
              frequency: insight.frequency,
              spend: insight.spend,
              until: insight.until
            }
          ]),
        []
      )
      .reverse()
  }

  get costChartData() {
    return this.campaign.insights
      .reduce((acc, insight) => {
        const ctr = insight.impressions > 0 ? (insight.clicks / insight.impressions) * 100 : 0
        const cpm = insight.impressions > 0 ? (insight.spend / insight.impressions) * 100 : 0
        const cpc = insight.clicks > 0 ? (insight.spend / insight.clicks) * 100 : 0
        const cpp = insight.reach > 0 ? (insight.spend / insight.reach) * 100 : 0
        return (acc = [...acc, { ctr, cpm, cpc, cpp, until: insight.until }])
      }, [])
      .reverse()
  }

  getPurchases(insights: any[]) {
    return insights.reduce((acc, insight) => {
      const purchase = insight.actions.find(a => a.name === 'purchase')
      if (!purchase) return acc
      return acc + purchase.count
    }, 0)
  }

  applyFiltersOnCharts(data: any[], statType: IBaseInsightStat | ICostInsightStat) {
    const filteredData = data
      .map(insight => {
        const fromDate = new Date(this.date)
        const insightDate = new Date(insight.until)
        let formatedInsight = { xAxisData: insightDate.toLocaleDateString(), yAxisData: insight[statType] }
        if (this.date) {
          if (insightDate < fromDate) {
            formatedInsight = undefined
          }
        }
        return formatedInsight
      })
      .filter(i => i !== undefined)

    const xAxisData = filteredData.map(i => i.xAxisData)
    const yAxisData = filteredData.map(i => i.yAxisData)
    return { xAxisData, yAxisData }
  }

  chartData(stat: IBaseInsightStat | ICostInsightStat) {
    const costStats = ['ctr', 'cpm', 'cpc', 'cpp']
    if (costStats.includes(stat)) return this.applyFiltersOnCharts(this.costChartData, stat)
    else return this.applyFiltersOnCharts(this.baseChartData, stat)
  }

  showChart(field: IBaseInsightStat | ICostInsightStat) {
    return this.select.map(i => i.toLowerCase()).includes(field.toLowerCase())
  }
}
