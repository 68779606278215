
import { Component, Prop } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'
import EditAdSetCopyCampaign from './EditAdSetCopyCampaign.vue'
@Component({ components: { EditAdSetCopyCampaign } })
export default class ConfigureAdSets extends VueStrong {
  @Prop({ required: true }) panelIndex: number

  get currentFbAccount() {
    return this.$store.state.account.adAccount
  }

  get accounts() {
    return this.$store.state.account.accounts
  }

  get adSets() {
    return this.$store.state.insight.AdCampaignAdsets.adSets
  }

  get panel() {
    return this.panelIndex
  }

  set panel(value) {
    this.$emit('update-panel-index', value)
  }

  updateVariation(adSet, index) {
    this.adSets[index] = adSet
    this.$store.commit('insight/setAdSetVariation', this.adSets)
  }
}
