
import { Component, Prop } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'
import EditAdCopyCampaign from './EditAdCopyCampaign.vue'
@Component({ components: { EditAdCopyCampaign } })
export default class ConfigureAds extends VueStrong {
  @Prop({ required: true }) panelIndex: number

  formRefs = []
  panels = []

  get ads() {
    const updatedAds = this.$store.state.insight.AdCampaignAdsets.ads.map(ad => {
      const {
        creative: { object_story_spec }
      } = ad
      const { link_data, photo_data, video_data, text_data } = object_story_spec
      ad.creative = { ...ad.creative, ...object_story_spec, ...link_data, ...photo_data, ...video_data, ...text_data }
      if (!ad.pictures) {
        const { link_data, video_data } = object_story_spec
        if (!link_data && !video_data) {
          ad['pictures'] = ad.creative.thumbnail_url ? [{ name: 'default_pic', src: ad.creative.thumbnail_url }] : []
        } else {
          ad['pictures'] = []
        }
      }
      if (!ad.videos) {
        ad['videos'] = ad.adVideo ? [{ ...ad.adVideo, src: ad.adVideo.source }] : []
      }
      return ad
    })

    for (let x = 0; x < updatedAds.length; x++) {
      this.panels.push(x)
    }

    return updatedAds
  }

  updateVariation(ad, index) {
    this.ads[index] = ad
    this.$store.commit('insight/setAdVariation', this.ads)
  }
  onAddFormRef(form: any) {
    this.formRefs.push(form)
  }
  async onContinue() {
    // Validate all form refs and track indexes with errors
    const indexesWithErrors = this.formRefs
      .map((form, i) => ({ form, i, valid: form.validate() }))
      .filter(entry => !entry.valid)
      .map(entry => entry.i)

    // Update 'hasErrors' property for ads
    this.ads.forEach((ad, i) => {
      ad.hasErrors = indexesWithErrors.includes(i)
    })

    // Commit changes to the store
    this.$store.commit('insight/setAdVariation', this.ads)

    if (indexesWithErrors.length === 0) {
      // No errors, emit the 'continue' event
      this.$emit('continue')
    }
  }
}
