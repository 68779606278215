
import { Component, Prop } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'
@Component
export default class SelectAccountStep extends VueStrong {
  @Prop({ default: true }) isLoading!: boolean
  @Prop({ default: {} }) selectedAccount!: any

  required = (val: string): boolean | string => !!val || 'Required'

  businessAccounts = []
  adAccounts = []
  facebookPages = []
  instagramPages = []
  facebookPixels = []

  get currentFbAccount() {
    return this.$store.state.account.adAccount
  }

  get accounts() {
    return this.$store.state.account.accounts
  }

  get fbAccounts() {
    return this.$store.state.account.accounts.map(account => ({ name: account.name, value: account._id }))
  }

  async filterBusinesses(accountId: string) {
    const businessesList = this.accounts.find(account => account._id === accountId)
    const { businesses } = businessesList
    return businesses.map(business => ({ name: business.name, value: business.id }))
  }
  async filterFacebookPages(businessId: string) {
    const businessList = this.accounts.find(account => account._id === this.selectedAccount.account)
    const business = businessList.businesses.find(bussines => bussines.id === businessId)
    const { pages, adAccounts, instagramAccounts, pixels } = business
    this.adAccounts = adAccounts.map(item => ({ name: item.name, value: item.id }))
    this.facebookPages = pages.map(item => ({ name: item.name, value: item.id }))
    this.instagramPages = instagramAccounts.map(item => ({ name: item.name, value: item.id }))
    this.facebookPixels = pixels.map(item => ({ name: item.name, value: item.id }))
  }
  async updateAccount(id: string) {
    this.businessAccounts = await this.filterBusinesses(id)
    this.$store.commit('insight/setSelectedAccount', this.selectedAccount)
  }
  async updateBusiness(id: string) {
    await this.filterFacebookPages(id)
    this.$store.commit('insight/setSelectedAccount', this.selectedAccount)
  }
  updateSelectedAccount() {
    this.$store.commit('insight/setSelectedAccount', this.selectedAccount)
  }
  async onContinue() {
    const valid = (this.$refs.formAccount as any).validate()
    if (!valid) return
    this.$emit('continue')
  }
}
