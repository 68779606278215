
import { Component, Watch } from 'vue-property-decorator'
import EditCampaign from '@/modules/campaign/components/EditCampaign.vue'
import CampaignsList from '@/modules/insight/components/CampaignsList.vue'
import AdSetsList from '@/modules/insight/components/AdSetsList.vue'
import AdsList from '@/modules/insight/components/AdsList.vue'
import CampaignFilters from '@/modules/insight/components/CampaignFilters.vue'
import AdSetFilters from '@/modules/insight/components/AdSetFilters.vue'
import AdFilters from '@/modules/insight/components/AdFilters.vue'
import VueStrong from '@/VueStrong'

@Component({
  components: { EditCampaign, AdSetsList, CampaignsList, AdsList, CampaignFilters, AdSetFilters, AdFilters }
})
export default class Insights extends VueStrong {
  loading = false
  snackbarSuccess = false
  snackbarError = false

  activeTab = 0

  get selectedAdAccount() {
    return this.$store.state.account ? this.$store.state.account.adAccount || '' : ''
  }

  get selectedCampaignId() {
    return this.$store.state.insight ? this.$store.state.insight.selectedCampaignId || '' : ''
  }

  get selectedAdSetId() {
    return this.$store.state.insight ? this.$store.state.insight.selectedAdSetId || '' : ''
  }

  @Watch('selectedAdAccount')
  onAdAccountChange() {
    this.activeTab = 0
    this.$store.dispatch('insight/setSelectedCampaignId', null)
    this.$store.dispatch('insight/setSelectedAdSetId', null)
  }

  onSelectAdSet() {
    this.activeTab = 2
  }

  onSelectCampagin() {
    this.activeTab = 1
  }
}
