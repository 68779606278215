
import { Component, Prop } from 'vue-property-decorator'
import { bidStrategies } from '@/helpers/data'
import SettingsCard from '@/components/SettingsCard.vue'
import NumberInput from '@/components/NumberInput.vue'
import CampaignBudget from '@/modules/campaign/components/CampaignBudget.vue'
import CampaignObjective from '@/modules/campaign/components/CampaignObjective.vue'
import VueStrong from '@/VueStrong'

interface IEditableCampaign extends yakkyo.IFacebookCampaign {
  adSets: yakkyo.IFacebookAdSet[]
}
@Component({ components: { SettingsCard, NumberInput, CampaignBudget, CampaignObjective } })
export default class EditCampaign extends VueStrong {
  @Prop({ required: true }) campaignId: string

  snackbarError = false
  snackbarSuccess = false
  loading = false

  editError = false

  editableCampaign: IEditableCampaign | null = null

  get bidStrategies() {
    return bidStrategies
  }

  created() {
    this.getCampaign()
  }

  async getCampaign() {
    try {
      this.loading = true
      this.editableCampaign = await this.$store.dispatch('insight/getCampaign', this.campaignId)
    } catch (e) {
      this.snackbarError = true
    } finally {
      this.loading = false
    }
  }

  checkForErrors() {
    this.editError = false
    if (this.editableCampaign) {
      if (!this.editableCampaign.campaignName) this.editError = true
      if (this.editableCampaign.cbo) {
        if (!this.editableCampaign.budgetWindow || !this.editableCampaign.budget || !this.editableCampaign.bidStrategy)
          this.editError = true
      } else {
        const adsetBudgetsFilled = this.editableCampaign.adSets.every(a => !!a.budget)
        const adsetBudgetsWindowFilled = this.editableCampaign.adSets.every(a => !!a.budgetWindow)
        if (!adsetBudgetsFilled || !adsetBudgetsWindowFilled) this.editError = true
      }
    }
  }

  async updateCampaign() {
    try {
      this.loading = true

      this.checkForErrors()
      if (this.editError) return

      const payload = {
        status: this.editableCampaign.status,
        name: this.editableCampaign.campaignName,
        objective: this.editableCampaign.objective,
        bidStrategy: this.editableCampaign.bidStrategy,
        budget: this.editableCampaign.budget,
        budgetWindow: this.editableCampaign.budgetWindow,
        cbo: this.editableCampaign.cbo,
        adSets: (this.editableCampaign.adSets || []).map(adSet => ({
          id: adSet.adSetId,
          budget: adSet.budget,
          budgetWindow: adSet.budgetWindow
        }))
      }
      await this.$store.state.apiClient.updateCampaign(this.editableCampaign._id, payload)
      this.$emit('close')
      this.snackbarSuccess = true
    } catch (error) {
      this.snackbarError = false
    } finally {
      this.loading = false
    }
  }

  onStatusChange(value: boolean) {
    this.editableCampaign.status = value ? 'ACTIVE' : 'PAUSED'
  }
}
