
import { Component, Prop } from 'vue-property-decorator'
import NumberInput from '@/components/NumberInput.vue'
import VueStrong from '@/VueStrong'
import SettingsCard from '@/components/SettingsCard.vue'
import { callToActions } from '@/helpers/data'

type utmParam = 'utm_source' | 'utm_medium' | 'utm_campaign' | 'utm_term' | 'utm_content'

@Component({ components: { NumberInput, SettingsCard } })
export default class EditAd extends VueStrong {
  @Prop({ required: true }) ad: any

  loading = false

  get utmParams() {
    return decodeURI(this.ad.creative.url_tags)
  }

  get campaignObjective() {
    return this.ad.campaign.objective
  }

  get buttonLabels() {
    return callToActions.filter(a => a.picture.includes(this.campaignObjective))
  }

  getUtmParam(param: utmParam) {
    return this.utmParams
      .split('&')
      .find(p => p.includes(param))
      .split('=')[1]
  }

  setUtmParams(param: utmParam, value: string) {
    const params = this.utmParams.split('&')
    const paramIndex = params.findIndex(p => p.includes(param))
    if (paramIndex >= 0) params[paramIndex] = param + '=' + value
    this.ad.creative.url_tags = params.join('&')
  }

  async updateAd() {
    try {
      this.loading = true
      const payload = { ...this.ad }
      delete payload.campaign

      if (payload?.creative?.object_story_spec?.link_data?.call_to_action?.value)
        payload.creative.object_story_spec.link_data.call_to_action.value.link =
          payload.creative.object_story_spec.link_data.link

      if (payload?.creative?.object_story_spec?.video_data?.call_to_action?.value)
        payload.creative.object_story_spec.video_data.call_to_action.value.link =
          payload.creative.object_story_spec.video_data.link
      await this.$store.state.apiClient.updateAd(this.ad.id, payload)
      this.$emit('successNotification', true)
      this.$emit('close')
    } catch (error) {
      console.log(error)
      this.$emit('errorNotification', true)
    } finally {
      this.loading = false
    }
  }
}
