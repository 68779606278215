
import moment from 'moment'
import { Component } from 'vue-property-decorator'
import FiltersMixin from '@/modules/insight/mixins/FiltersMixin.vue'

@Component({ mixins: [FiltersMixin] })
export default class CampaignFilters extends FiltersMixin {
  filtersType = 'campaign'
  paramsField = 'campaignParams'
  updateAction = `insight/updateCampaignInsights`
  setFilterAction = `insight/setCampaignParams`
  getAction = `insight/getCampaigns`

  campaignName = ''
  campaignId = ''
  effectiveStatus = ''

  statuses = [
    { key: 'Active', value: 'ACTIVE' },
    { key: 'Paused', value: 'PAUSED' },
    { key: 'Deleted', value: 'DELETED' },
    { key: 'Archived', value: 'ARCHIVED' },
    { key: 'In Process', value: 'IN_PROCESS' },
    { key: 'With Issues', value: 'WITH_ISSUES' }
  ]

  initialParams = {
    campaignName: '',
    campaignId: '',
    effectiveStatus: '',
    start: this.formatDate(moment().subtract(1, 'day')),
    end: this.formatDate(moment())
  }

  created() {
    this.campaignName = this.params.campaignName || ''
    this.campaignId = this.params.campaignId || ''
    this.effectiveStatus = this.params.campaignStatus || ''
  }

  get selectedAdAccount() {
    return this.$store.state.account ? this.$store.state.account.adAccount || '' : ''
  }
}
