
import { Component, Prop } from 'vue-property-decorator'
import NumberInput from '@/components/NumberInput.vue'
import VueStrong from '@/VueStrong'
import { bidStrategies } from '@/helpers/data'
import moment from 'moment'

@Component({ components: { NumberInput } })
export default class EditAdSet extends VueStrong {
  @Prop({ required: true }) adSetId: string

  toggleBudget = 0
  snackbarError = false
  snackbarSuccess = false
  loading = false

  time = null
  adSet: FB.IAdSet | null = null

  async created() {
    this.loading = true
    if (!this.adSetId) return
    try {
      const { data } = await this.$store.state.apiClient.getAdSet(this.adSetId)
      if (data.start_time) data.start_time = moment(data.start_time).toDate()
      if (data.end_time) data.end_time = moment(data.end_time).toDate()
      this.adSet = data
    } catch (error) {
      console.log(error)
    }
    this.loading = false
  }

  get updateBudget() {
    if (!!this.adSet.daily_budget || !!this.adSet.lifetime_budget) return true
    return false
  }

  get bidStrategies() {
    return bidStrategies
  }

  get showBidAmount() {
    // TODO: this is not totally correct (bid_strategy might be on campaign)
    if (this.adSet.bid_strategy === 'LOWEST_COST_WITH_BID_CAP') return false
    return true
  }

  async updateAdSet() {
    this.loading = true

    try {
      const payload = { ...this.adSet }
      if (payload.bid_strategy !== 'LOWEST_COST_WITH_BID_CAP') delete payload.bid_amount
      // delete payload.daily_budget

      if (!this.toggleBudget) delete payload.lifetime_budget
      else delete payload.daily_budget

      if (payload.start_time) payload.start_time = moment(payload.start_time).unix() + ''
      if (payload.end_time) payload.end_time = moment(payload.end_time).unix() + ''

      // FIXME: double check this part
      await this.$store.state.apiClient.updateAdSet(payload.id + '', payload)
      this.$emit('close')
      this.snackbarSuccess = true
    } catch (error) {
      console.log(error)
      this.snackbarError = true
    }
    this.loading = false
  }
}
