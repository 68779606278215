
import { Component, Watch } from 'vue-property-decorator'
import ListsMixin from '@/modules/insight/mixins/ListsMixin.vue'
import ButtonConfirm from '@/components/ButtonConfirm.vue'
import EditAdSet from '@/modules/adset/components/EditAdSet.vue'

@Component({ components: { ButtonConfirm, EditAdSet }, mixins: [ListsMixin] })
export default class AdSetsList extends ListsMixin {
  filtersType = 'adset'

  listDataAction = 'insight/getAdSets'
  setParamsAction = 'insight/setAdSetParams'

  get headers() {
    return [
      { text: 'Status', align: 'start', sortable: false, value: 'status' },
      { text: 'Ad Set Name', align: 'start', sortable: false, value: 'name' },
      { text: `Spent (${this.currency})`, value: 'totalSpent', sortable: false },
      { text: 'Reach', value: 'reach', sortable: false },
      { text: 'Clicks', value: 'clicks', sortable: false },
      { text: 'Impressions', value: 'impressions', sortable: false },
      { text: 'Frequency', value: 'frequency', sortable: false },
      { text: 'CTR', value: 'ctr', sortable: false },
      { text: 'CPM', value: 'cpm', sortable: false },
      { text: 'CPC', value: 'cpc', sortable: false },
      { text: 'CPP', value: 'cpp', sortable: false },
      { text: 'Purchass', value: 'purchases', sortable: false },
      { text: '', value: 'insights', sortable: false }
    ]
  }

  editingAdSet = ''

  get params() {
    return this.$store.state.insight?.adSetParams || {}
  }

  get adSets() {
    return this.$store.state.insight ? this.$store.state.insight.adSets || [] : []
  }

  get parentId() {
    if (this.filtersType === 'adset')
      return this.$store.state.insight ? this.$store.state.insight.selectedCampaignId || '' : ''
    if (this.filtersType === 'ad')
      return this.$store.state.insight ? this.$store.state.insight.selectedAdSetId || '' : ''
    return ''
  }

  get selectedAdAccount() {
    return this.$store.state.account ? this.$store.state.account.adAccount : null
  }

  get currency() {
    if (!this.selectedAdAccount) return 'USD'
    return this.selectedAdAccount.currency || 'USD'
  }

  @Watch('parentId')
  getAdsets() {
    if (this.parentId) this.listData()
  }

  async toggleStatus(adSet: yakkyo.IFacebookAdSet) {
    this.$emit('setLoading', true)
    try {
      const payload = { status: adSet.status.toLowerCase() === 'active' ? 'PAUSED' : 'ACTIVE' }
      await this.$store.state.apiClient.updateAdSet(adSet._id, payload)
    } catch (error) {
      this.$emit('errorNotification', true)
    }

    this.$emit('setLoading', false)

    await this.debounceGetData()
  }

  getReducedReachData(insights: any[], field: string) {
    return insights.reduce((acc, insight) => acc + insight[field], 0)
  }

  getCtr(insights: any[]) {
    const clicks = insights.reduce((acc, insight) => acc + insight.clicks, 0)
    const impressions = insights.reduce((acc, insight) => acc + insight.impressions, 0)
    const ctr = (clicks / impressions) * 100
    return impressions > 0 ? ctr.toFixed(2) : 0
  }

  getCpm(insights: any[]) {
    const spend = insights.reduce((acc, i) => acc + i.spend, 0)
    const impressions = insights.reduce((acc, insight) => acc + insight.impressions, 0)
    const cpm = (spend / impressions) * 1000
    return impressions > 0 ? cpm.toFixed(2) : 0
  }

  getCpc(insights: any[]) {
    const spend = insights.reduce((acc, i) => acc + i.spend, 0)
    const clicks = insights.reduce((acc, insight) => acc + insight.clicks, 0)
    const cpc = spend / clicks
    return clicks > 0 ? cpc.toFixed(2) : 0
  }

  getCpp(insights: any[]) {
    const spend = insights.reduce((acc, i) => acc + i.spend, 0)
    const purchases = this.getPurchases(insights)
    const cpp = spend / purchases
    return purchases ? cpp.toFixed(2) : spend.toFixed(2)
  }

  getPurchases(insights: any[]) {
    return insights.reduce((acc, insight) => {
      const purchase = insight.actions.find(a => a.name === 'purchase')
      if (!purchase) return acc
      return acc + purchase.count
    }, 0)
  }

  onItemSelect(item: yakkyo.IFacebookAdSet) {
    this.$store.dispatch('insight/setSelectedAdSetId', item)
    this.$emit('selectAdSet')
  }

  async copyAdSet(item: yakkyo.IFacebookAdSet) {
    await this.$store.dispatch('insight/copyAdSet', item._id)
    await this.listData()
  }
}
