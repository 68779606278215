
import { Component, Watch } from 'vue-property-decorator'
import EditCampaign from '@/modules/campaign/components/EditCampaign.vue'
import moment from 'moment'
import InsightsDialog from './InsightsDialog.vue'
import ListsMixin from '@/modules/insight/mixins/ListsMixin.vue'
import ButtonConfirm from '@/components/ButtonConfirm.vue'
import CopyCampaignModal from '@/modules/insight/components/CopyCampaignModal.vue'

@Component({ components: { EditCampaign, InsightsDialog, ButtonConfirm, CopyCampaignModal }, mixins: [ListsMixin] })
export default class CampaignsList extends ListsMixin {
  filtersType = 'campaign'

  insightDialog = null
  showCopyCampaignModal = false
  selectedModalCampaignId = null
  editCampaign = null
  snackbar = false
  snackBarText = ''
  snackBarColor = 'success'

  listDataAction = 'insight/getCampaigns'
  setParamsAction = 'insight/setCampaignParams'

  get campaignHeaders() {
    return [
      { text: 'Status', sortable: false, value: 'status' },
      { text: 'Campaign Name', sortable: false, value: 'campaignName' },
      { text: `Spent (${this.currency})`, value: 'totalSpent', sortable: false },
      { text: 'Reach', value: 'reach', sortable: false },
      { text: 'Clicks', value: 'clicks', sortable: false },
      { text: 'Impressions', value: 'impressions', sortable: false },
      { text: 'Frequency', value: 'frequency', sortable: false },
      { text: 'CTR', value: 'ctr', sortable: false },
      { text: 'CPM', value: 'cpm', sortable: false },
      { text: 'CPC', value: 'cpc', sortable: false },
      { text: 'CPP', value: 'cpp', sortable: false },
      { text: 'Purchass', value: 'purchases', sortable: false },
      { text: '', value: 'insights', sortable: false }
    ]
  }

  get params() {
    return this.$store.state.insight?.campaignParams || {}
  }

  get parentId() {
    if (this.filtersType === 'adset')
      return this.$store.state.insight ? this.$store.state.insight.selectedCampaignId || '' : ''
    if (this.filtersType === 'ad')
      return this.$store.state.insight ? this.$store.state.insight.selectedAdSetId || '' : ''
    return ''
  }

  get campaigns() {
    return this.$store.state.insight ? this.$store.state.insight.campaigns || [] : []
  }

  get selectedAdAccount(): any {
    return this.$store.state.account ? this.$store.state.account.adAccount : null
  }

  get currency() {
    if (!this.selectedAdAccount) return 'USD'
    return this.selectedAdAccount.currency || 'USD'
  }

  get editingCampaignName() {
    const campaignBeforeEdit = this.campaigns.find(c => c._id === this.editCampaign._id)
    return campaignBeforeEdit.campaignName
  }

  getReducedReachData(insights: any[], field: string) {
    return insights.reduce((acc, insight) => acc + insight[field], 0)
  }

  getCtr(insights: any[]) {
    const clicks = insights.reduce((acc, insight) => acc + insight.clicks, 0)
    const impressions = insights.reduce((acc, insight) => acc + insight.impressions, 0)
    const ctr = (clicks / impressions) * 100
    return impressions > 0 ? ctr.toFixed(2) : 0
  }

  getCpm(insights: any[]) {
    const spend = insights.reduce((acc, i) => acc + i.spend, 0)
    const impressions = insights.reduce((acc, insight) => acc + insight.impressions, 0)
    const cpm = (spend / impressions) * 1000
    return impressions > 0 ? cpm.toFixed(2) : 0
  }

  getCpc(insights: any[]) {
    const spend = insights.reduce((acc, i) => acc + i.spend, 0)
    const clicks = insights.reduce((acc, insight) => acc + insight.clicks, 0)
    const cpc = spend / clicks
    return clicks > 0 ? cpc.toFixed(2) : 0
  }

  getCpp(insights: any[]) {
    const spend = insights.reduce((acc, i) => acc + i.spend, 0)
    const purchases = this.getPurchases(insights)
    const cpp = spend / purchases
    return purchases ? cpp.toFixed(2) : spend.toFixed(2)
  }

  getPurchases(insights: any[]) {
    return insights.reduce((acc, insight) => {
      const purchase = insight.actions.find(a => a.name === 'purchase')
      if (!purchase) return acc
      return acc + purchase.count
    }, 0)
  }

  async closeEditCampaign() {
    this.editCampaign = null
    await this.debounceGetData()
  }

  async toggleStatus(campaign: yakkyo.IFacebookCampaign) {
    this.$emit('setLoading', true)
    try {
      const payload = { status: campaign.status.toLowerCase() === 'active' ? 'PAUSED' : 'ACTIVE' }
      await this.$store.state.apiClient.updateCampaign(campaign._id, payload)
    } catch (error) {
      this.$emit('errorNotification', true)
    }

    this.$emit('setLoading', false)

    await this.debounceGetData()
  }

  @Watch('parentId')
  getAdsets() {
    if (this.parentId) this.listData()
  }

  formatDate(date: string) {
    return moment(date).format('YYYY-MM-DD')
  }

  onItemSelect(item: yakkyo.IFacebookCampaign) {
    this.$store.dispatch('insight/setSelectedCampaignId', item)
    this.$emit('selectCampagin')
  }

  async copyCampaign(item: yakkyo.IFacebookCampaign) {
    const payload = {
      id: item._id,
      adAccount: this.selectedAdAccount
    }
    await this.$store.dispatch('insight/copyCampaign', payload)
    await this.listData()
  }
  showCampaignModal(campaignId: string) {
    this.selectedModalCampaignId = campaignId
    this.showCopyCampaignModal = true
  }
  closeCampaignModal() {
    this.showCopyCampaignModal = false
    this.selectedModalCampaignId = null
  }
  showSnackBar(snack: any) {
    this.snackBarText = snack.text
    this.snackBarColor = snack.color
    this.snackbar = snack.show
  }
  async copyCampaignToAnotherAccount(item: yakkyo.IFacebookCampaign) {
    console.log('item', item)
  }
}
