
import { Component, Prop } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'

@Component
export default class ButtonConfirm extends VueStrong {
  @Prop({ default: '' }) color!: string
  @Prop({ default: false }) plain!: boolean
  @Prop({ default: false }) icon!: boolean
  @Prop({ default: false }) small!: boolean
  @Prop({ default: 1 }) elevetion!: number
  @Prop({ default: 'Are you sure?' }) text!: string
  @Prop({ default: 'Cancel' }) textCancel!: string
  @Prop({ default: 'Confirm' }) textConfirm!: string

  showConfirm = false

  onConfirm() {
    this.$emit('click')
    this.showConfirm = false
  }
}
