
import { Component, Prop } from 'vue-property-decorator'
import { CanvasRenderer } from 'echarts/renderers'
import { LineChart } from 'echarts/charts'
import { TitleComponent, TooltipComponent, LegendComponent, ToolboxComponent, GridComponent } from 'echarts/components'
import VChart from 'vue-echarts'
import VueStrong from '@/VueStrong'
import { use } from 'echarts/core'
use([CanvasRenderer, TitleComponent, TooltipComponent, LegendComponent, ToolboxComponent, GridComponent, LineChart])

@Component({
  components: { 'v-chart': VChart }
})
export default class SmoothedLineChart extends VueStrong {
  @Prop({ required: true }) title: string
  @Prop({ required: true }) chartData: { xAxisData: string[]; yAxisData: string[] }

  get xAxisData() {
    return this.chartData.xAxisData
  }
  get yAxisData() {
    return this.chartData.yAxisData
  }

  get noData() {
    if (this.xAxisData.length === 0 || this.yAxisData.length === 0) return true
    else return false
  }

  get options() {
    return {
      title: {
        text: this.title
      },
      xAxis: {
        type: 'category',
        data: this.xAxisData
      },
      yAxis: {
        type: 'value'
      },
      tooltip: {
        trigger: 'axis'
      },
      grid: {
        left: '15%'
      },
      series: [
        {
          data: this.yAxisData,
          type: 'line',
          smooth: true
        }
      ]
    }
  }
}
