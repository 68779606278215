
import { Component } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'
import { callToActions } from '@/helpers/data'
@Component
export default class ConfirmCopyCampaign extends VueStrong {
  isLoading = false

  get selectedAdCampaign() {
    return this.$store.state.insight.AdCampaignAdsets
  }

  get account() {
    return this.selectedAdCampaign.selectedAccout
  }

  get adSets() {
    return this.selectedAdCampaign.adSets
  }

  get ads() {
    return this.selectedAdCampaign.ads
  }

  get accounts() {
    return this.$store.state.account.accounts
  }

  async onContinue() {
    try {
      this.isLoading = true
      const { data } = await this.$store.dispatch('insight/copyCampaignToAnotherAccount', this.selectedAdCampaign)
      const snack = {
        text: data.message,
        color: 'success',
        show: true
      }
      this.$emit('remove-campaign-id')
      this.$emit('show-snackbar', snack)
      this.$emit('cancel')
    } catch (error) {
      const snack = {
        text: 'Error :' + error.response.data.error,
        color: 'red accent-2',
        show: true
      }
      this.$emit('show-snackbar', snack)
      console.log(error)
    } finally {
      this.isLoading = false
    }
  }
  parseCTA(cta: string) {
    return callToActions.find(c => c.value === cta).key
  }

  get page() {
    const business = this.accounts
      .find(account => account._id === this.account.account)
      ?.businesses.find(business => business.id === this.account.business)
    return business?.pages.find(page => page.id === this.account.facebookPage) || ''
  }
}
