
import { Component, Prop, Watch } from 'vue-property-decorator'
import NumberInput from '@/components/NumberInput.vue'
import VueStrong from '@/VueStrong'
import SettingsCard from '@/components/SettingsCard.vue'
import { objectives } from '@/helpers/data'
import { bidStrategies } from '@/helpers/data'

@Component({ components: { NumberInput, SettingsCard } })
export default class EditAdSetCopyCampaign extends VueStrong {
  @Prop({ required: true }) adSet: any
  @Prop({ required: true }) index: number

  // required = (val: string): boolean | string => !!val || 'Required'

  required = [v => !!v || '*Required']

  get cbo() {
    return this.$store.state.insight.AdCampaignAdsets.campaign.cbo
  }
  get budgetWindow() {
    return this.$store.state.insight.AdCampaignAdsets.campaign.budgetWindow
  }
  get spendCapLabel() {
    return this.budgetWindow === 'LIFETIME'
      ? `Lifetime Spend CAP (${this.currency})`
      : `Daily Spend CAP (${this.currency})`
  }

  get selectedAdAccount() {
    return this.$store.state.account ? this.$store.state.account.adAccount : null
  }

  get currency() {
    if (!this.selectedAdAccount) return 'USD'
    return this.selectedAdAccount.currency || 'USD'
  }

  get bidStrategies() {
    return bidStrategies
  }
  get campaign() {
    return this.$store.state.insight.AdCampaignAdsets.campaign
  }

  get targetObjective() {
    return objectives.find(o => o.value === this.campaign.objective)
  }

  get optiomizationGoals() {
    return this.targetObjective ? this.targetObjective.optimizationGoals : []
  }

  get showBid() {
    if (this.campaign.cbo) return this.campaign.bid_strategy !== 'LOWEST_COST_WITHOUT_CAP'
    return this.adSet.bidStrategy !== 'LOWEST_COST_WITHOUT_CAP'
  }

  @Watch('adSet.bidAmount', { deep: true, immediate: true })
  onBidAmount(value) {
    if (!value) this.adSet.bidAmount = 0
  }

  updateBidStrategy(value: number) {
    this.adSet.bidStrategy = value
    this.updateVariation()
  }
  updateAdsetBudget(value: number) {
    this.adSet.budget = value
    this.updateVariation()
  }
  updateAdsetSpendCap(value: number) {
    this.adSet.spendCap = value
    this.updateVariation()
  }
  onBudgetWindowChange(value: number) {
    this.adSet.budgetWindow = value
    this.updateVariation()
  }

  updateVariation() {
    this.$emit('update-variation', this.adSet, this.index)
  }
}
