
import { Component, Prop, Watch } from 'vue-property-decorator'
import NumberInput from '@/components/NumberInput.vue'
import VueStrong from '@/VueStrong'
import SettingsCard from '@/components/SettingsCard.vue'
import { callToActions } from '@/helpers/data'

type utmParam = 'utm_source' | 'utm_medium' | 'utm_campaign' | 'utm_term' | 'utm_content'

@Component({ components: { NumberInput, SettingsCard } })
export default class EditAdCopyCampaign extends VueStrong {
  @Prop({ required: true }) ad: any
  @Prop({ required: true }) index: number

  required = (val: string): boolean | string => !!val || 'Required'

  tab = null
  defaultUrlTags =
    'utm_medium=%7B%7Bplacement%7D%7D&utm_campaign=%7B%7Bcampaing.name%7D%7D&utm_source=cpc&utm_term=%7B%7Bad.id%7D%7D&utm_content=%7B%7Bad.name%7D%7D'

  uploadDialog = false
  file = null
  loading = false
  uploadError = false

  validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg']
  files = []
  uploadPicturesPreview = []
  reRenderImages = 0
  reRenderVideos = 0

  get computedAd() {
    return this.ad
  }

  get utmParams() {
    if (!this.ad.creative.url_tags) return decodeURI(this.defaultUrlTags)
    return decodeURI(this.ad.creative.url_tags)
  }

  get campaignObjective() {
    return this.ad.campaign.objective
  }

  get buttonLabels() {
    return callToActions
  }

  get name() {
    return this.ad.name || ''
  }

  set name(value) {
    this.ad.name = value
    this.updateVariation()
  }

  get general() {
    return this.ad.creative
  }

  get title() {
    return this.general.title
  }
  set title(value) {
    this.ad.creative.title = value
    this.updateVariation()
  }

  get link() {
    if (!this.general.call_to_action) return ''
    return this.general.call_to_action.value.link || ''
  }
  set link(value) {
    const payload = {
      call_to_action: {
        type: this.ad.creative.call_to_action_type || 'SHOP_NOW',
        value: {
          link: value
        }
      }
    }
    this.ad.creative = { ...this.ad.creative, ...payload }
    this.updateVariation()
  }

  get description() {
    return this.general.description || ''
  }
  set description(value) {
    this.ad.creative.description = value
    this.updateVariation()
  }

  get message() {
    return this.general.message || ''
  }
  set message(value) {
    this.ad.creative.message = value
    this.updateVariation()
  }

  mounted() {
    this.$emit('add-form-ref', this.$refs.adForm)
  }

  getUtmParam(param: utmParam) {
    return this.utmParams
      .split('&')
      .find(p => p.includes(param))
      .split('=')[1]
  }

  setUtmParams(param: utmParam, value: string) {
    const params = this.utmParams.split('&')
    const paramIndex = params.findIndex(p => p.includes(param))
    if (paramIndex >= 0) params[paramIndex] = param + '=' + value
    this.ad.creative.url_tags = params.join('&')
    this.updateVariation()
  }

  updateVariation() {
    this.$emit('update-variation', this.ad, this.index)
  }

  addFiles() {
    if (this.uploadPicturesPreview.length === 0) return
    this.uploadPicturesPreview.forEach(file => {
      this.ad.pictures.push({ src: file.src, name: file.name })
    })
    this.updateVariation()
    this.uploadDialog = false
  }
  removeFile(file: any) {
    const filtered = this.uploadPicturesPreview.filter(picture => picture.name !== file.name)
    const filteredFiles = this.files.filter(picture => picture.name !== file.name)
    this.uploadPicturesPreview = filtered
    this.files = filteredFiles
  }
  removeImageFromAd(name: string) {
    const index = this.ad.pictures.findIndex(picture => picture.name === name)
    if (index !== -1) {
      this.ad.pictures.splice(index, 1)
    }
    this.reRenderImages++
  }
  removeVideoFromAd(src: string) {
    const index = this.ad.videos.findIndex(picture => picture.src === src)
    if (index !== -1) {
      this.ad.videos.splice(index, 1)
    }
    this.reRenderVideos++
  }

  onFileChanged(files) {
    if (!files) return
    if (files.length > 0) {
      files.forEach(file => {
        const reader = new FileReader()
        reader.onload = e => {
          this.uploadPicturesPreview.push({ ...file, name: file.name, src: e.target.result })
        }
        reader.readAsDataURL(file)
      })
    }
  }
  closeUploadDialog() {
    this.uploadDialog = false
    this.uploadError = false
  }
  onCTAChange(val: any) {
    this.ad.creative.call_to_action_type = val
    this.updateVariation()
  }
}
