
import { Component, Prop, Watch } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'
import SelectAccountStep from './CampaignModal/SelectAccountStep.vue'
import ConfigureAds from './CampaignModal/ConfigureAds.vue'
import ConfigureAdSets from './CampaignModal/ConfigureAdSets.vue'
import ConfigureCampaign from './CampaignModal/ConfigureCampaign.vue'
import ConfirmCopyCampaign from './CampaignModal/ConfirmCopyCampaign.vue'

@Component({ components: { SelectAccountStep, ConfigureAds, ConfirmCopyCampaign, ConfigureAdSets, ConfigureCampaign } })
export default class CopyCampaignModal extends VueStrong {
  @Prop({ default: null }) adCampaignID!: string
  @Prop({ default: true }) modalStatus!: boolean
  @Prop({ default: 'Are you sure?' }) text!: string
  @Prop({ default: 'Cancel' }) textCancel!: string
  @Prop({ default: 'Continue' }) textContinue!: string
  @Prop({ default: 'Done' }) textDone!: string
  @Prop({ default: 'Back' }) textBack!: string

  selectedAccount = {
    account: null,
    business: null,
    adAccount: null,
    facebookPage: null,
    instagramPage: null,
    facebookPixel: null
  }

  stepValue = 1

  panelIndex = 0

  isLoading = true

  showConfirm = false

  get campaign() {
    return this.$store.state.insight.AdCampaignAdsets.campaign
  }
  get ads() {
    return this.$store.state.insight.AdCampaignAdsets.ads
  }

  get adSets() {
    return this.$store.state.insight.AdCampaignAdsets.adSets
  }

  get adAccountId() {
    return this.$store.state.account.adAccount.id
  }

  @Watch('adCampaignID')
  async onAdCampaignIDChange(val) {
    if (!val) return this.clearAll()
    await this.getAllCampaignData()
  }

  onConfirm() {
    this.$emit('confirm')
  }

  removeCampaignAndClear() {
    this.stepValue = 1
    this.selectedAccount = {
      account: null,
      business: null,
      adAccount: null,
      facebookPage: null,
      instagramPage: null,
      facebookPixel: null
    }
  }
  clearAll() {
    this.stepValue = 1
    this.selectedAccount = {
      account: null,
      business: null,
      adAccount: null,
      facebookPage: null,
      instagramPage: null,
      facebookPixel: null
    }
    this.$store.state.insight.AdCampaignAdsets.ads = []
    this.$store.state.insight.AdCampaignAdsets.adSets = []
    this.$emit('remove-campaign-id')
  }
  showSnackBar(snack: any) {
    this.$emit('show-snackbar', snack)
  }

  checkAdsValidation() {
    const indx = this.ads.findIndex(ad => ad.isComplete === false)
    if (indx !== -1) {
      this.stepValue = 4
      this.panelIndex = indx
    } else {
      this.stepValue = 5
    }
  }
  checkAdSetValidation() {
    const indx = this.adSets.findIndex(ad => ad.isComplete === false)
    if (indx !== -1) {
      this.stepValue = 3
      this.panelIndex = indx
    } else {
      this.stepValue = 4
    }
  }
  async getAllCampaignData() {
    try {
      this.isLoading = true
      const payload = {
        campaign: this.adCampaignID,
        adAccount: this.adAccountId
      }
      await this.$store.dispatch('insight/getAdCampaignAdsets', payload)
      this.$store.state.insight.AdCampaignAdsets.adAccountId = this.adAccountId
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoading = false
    }
  }
}
