
import moment from 'moment'
import { Component } from 'vue-property-decorator'
import FiltersMixin from '@/modules/insight/mixins/FiltersMixin.vue'

@Component({ mixins: [FiltersMixin] })
export default class AdSetFilters extends FiltersMixin {
  filtersType = 'adset'
  adSetName = ''
  adSetId = ''
  effectiveStatus = ''

  statuses = [
    { key: 'Active', value: 'ACTIVE' },
    { key: 'Paused', value: 'PAUSED' },
    { key: 'Deleted', value: 'DELETED' },
    { key: 'Campaign Paused', value: 'CAMPAIGN_PAUSED' },
    { key: 'Archived', value: 'ARCHIVED' },
    { key: 'In Process', value: 'IN_PROCESS' },
    { key: 'With Issues', value: 'WITH_ISSUES' }
  ]

  initialParams = {
    adSetName: '',
    adSetId: '',
    effectiveStatus: '',
    start: this.formatDate(moment().subtract(1, 'day')),
    end: this.formatDate(moment())
  }

  paramsField = 'adSetParams'
  updateAction = 'insight/updateAdSetInsights'
  setFilterAction = 'insight/setAdSetParams'
  getAction = 'insight/getAdSets'

  created() {
    this.adSetName = this.params.adSetName || ''
    this.adSetId = this.params.adSetId || ''
    this.effectiveStatus = this.params.effectiveStatus || ''
  }
}
